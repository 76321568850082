import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Box, Text, Heading, Button } from 'grommet';
import Layout from 'components/layout';
import Head from 'components/head';
import SectionTitle from 'components/title/sectionTitle';
import Img from 'gatsby-image';
import RouteButton from 'components/routeButton';
import Details from '../../../content/purchase/otosimMobile.mdx';

const itemPrice = '495.00';

const OtosimMobile = ({ data }) => (
  <Layout>
    <Head pageTitle={data.purchaseJson.title} />
    <Box fill="horizontal">
      <SectionTitle title="Buy OtoSim Mobile™ Now" />
      <Box width="xlarge" alignSelf="center">
        <Box
          pad="medium"
          direction="row-responsive"
          width="xlarge"
          alignSelf="center"
        >
          <Box width="large" margin="medium" alignSelf="center">
            <Img
              fluid={
                data.purchaseJson.otosimMobilePic
                  ? data.purchaseJson.otosimMobilePic.childImageSharp.fluid
                  : {}
              }
              alt="Card Image"
            />
            <Box>
              <Text margin="small" alignSelf="center">
                Have a question?
              </Text>
              <RouteButton to="/contact" label="Get in touch with us" />
            </Box>
          </Box>
          <Box width="80%" align="center" alignSelf="center">
            <Box alignSelf="start">
              <Heading
                level="3"
                size="small"
                responsive={true}
                alignSelf="start"
              >For students from the USA and Canada: USD {itemPrice}
              </Heading>
              <Button
                className="snipcart-add-item"
                data-item-id="1"
                data-item-name="OtoSim Mobile"
                data-item-price={itemPrice}
                data-item-url="https://otosim.com/purchase/otosim-mobile/"
                data-item-weight="997"
                data-item-description="Mobile Simulator"
                label="Add To Cart"
                primary={true}
                color="accent-4"
              />
            </Box>
            <Heading level="3" size="small" responsive={true} alignSelf="start">
              Product Details
            </Heading>
            <Text size="small">
              <Details />
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  </Layout>
);

OtosimMobile.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OtosimMobile;

export const query = graphql`
  query BuyOtosimMobileQuery {
    purchaseJson {
      title
      otosimMobilePic {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
