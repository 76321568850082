import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`OtoSim MobileTM – A smartphone-compatible hand-held otoscopy simulator with a complete otologic curriculum`}</MDXTag>
<MDXTag name="p" components={components}>{`FREE SHIPPING!`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Works with most smartphones and tablets`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Contains 400 real otologic cases with full descriptors`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Built-in randomized quizzes and exams for testing skills`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Includes:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`1 Clip-on smartphone attachment`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`1 Silicone Earform (right ear only)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`1 Functional otoscope for training purposes`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`1 Customized suction stand for tabletop use`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`1 Customized clamp stand for tabletop use
User Guide`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`1 Leather travel bag`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`1-Year hardware warranty`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`3-Year software subscription`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`*Note: We do not deliver to P.O. Boxes`}</MDXTag>
<MDXTag name="p" components={components}>{`**Applicable taxes will apply for Canadian purchases.`}</MDXTag>
<MDXTag name="p" components={components}>{`***With FREE SHIPPING option, the product will be delivered within 10 – 12 business days to a North American address. The product is shipped from Toronto, Canada.`}</MDXTag>
<MDXTag name="p" components={components}>{`***For Customers outside of Canada and the USA, please contact our international team to get a quote. Email: internationalsales@otosim.com `}</MDXTag></MDXTag>

export const _frontmatter = {};

  